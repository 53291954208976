
export default {
  name: 'AtomLocationType',
  props: {
    category: {
      required: false,
      type: String,
      default: ''
    },
    categoryLabel: {
      required: false,
      type: [String, Object],
      default: ''
    },
    totalNumber: {
      required: false,
      type: Number,
      default: 0
    },
    defaultMode: {
      required: false,
      type: String,
      default: 'walking'
    }
  },
  data() {
    return {
      isActive: false,
      filters: {}
    }
  },
  methods: {
    toggleSection() {
      this.filters = {
        selectedCategory: this.$el
          .querySelectorAll('.change-info-boxes')[0]
          .getAttribute('data-category'),
        transportationType: this.$el
          .querySelector('.common-tab.active')
          .getAttribute('data-transportation')
      }
      this.$parent.$parent.$refs.map.displayMarkersByCategory(this.filters)

      const getSiblings = function (elem) {
        return Array.prototype.filter.call(elem.parentNode.children, function (sibling) {
          return sibling !== elem
        })
      }
      const target = this.$el
      target.classList.toggle('active')

      this.$emit('category-toggle', target)
      getSiblings(target).forEach((sibling) => {
        sibling.classList.remove('active')
      })
    },
    selectWalkingSliderOption(e) {
      e.stopPropagation()
      const walkingButton = this.$el.querySelector('.walking-tab')
      const drivingButton = this.$el.querySelector('.driving-tab')
      if (!walkingButton.classList.contains('active')) {
        this.$emit('category-toggle', this.$el)
        walkingButton.classList.add('active')
        this.filters.transportationType = 'walking'
        this.$parent.$parent.$refs.map.displayMarkersByCategory(this.filters)
        drivingButton.classList.remove('active')
      }
    },
    selectDrivingSliderOption(e) {
      e.stopPropagation()
      const drivingButton = this.$el.querySelector('.driving-tab')
      const walkingButton = this.$el.querySelector('.walking-tab')
      if (!drivingButton.classList.contains('active')) {
        this.$emit('category-toggle', this.$el)
        drivingButton.classList.add('active')
        this.filters.transportationType = 'driving'
        this.$parent.$parent.$refs.map.displayMarkersByCategory(this.filters)
        walkingButton.classList.remove('active')
      }
    }
  }
}
